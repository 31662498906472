.post-single {

	padding: 3rem 0 0 0;

	&__content {
		margin: 0 auto;
		max-width: 720px;
		font-size: 1.125rem;
	}

	&__paytag {
		float: right;
		padding: 0.25rem 1rem;
		font-size: 0.875rem;
		color: $pure-white;

		&.post-single__paytag--basic {
			background-color: $brand-paywall-basic;
		}

		&.post-single__paytag--premium {
			background-color: $brand-paywall-premium;
		}
	}

	&__title {
		margin-bottom: 1.5rem;
		font-size: 1.875rem;
		font-weight: 400;
		color: $brand-dark-blue;
	}

	&__subtitle {
		// fs-10 fw-normal brand-blue mb-12
		margin-bottom: 3rem;
		font-size: 1.25rem;
		font-weight: 400;
		color: $brand-blue;
	}

	&__thumb {
		margin-bottom: 3rem;
	}

	&__footer {
		margin-top: 3rem;

		h3 {
			font-size: 1.5rem;
			font-weight: 400;
			color: $brand-dark-blue;
		}

	}

	&__footer-content {
		margin-bottom: 2.5rem;

		.btn {
			margin-top: 1rem;
		}
	}

	&__tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		list-style: none;
		margin: 0 0 1rem 0;
		padding: 0;
		> li {
			margin-right: 0.625rem;
			margin-bottom: 0.625rem;
		}
	}

	&__tag {
		padding: 0.1875rem 0.5rem;
		font-size: 0.875rem;
		border: solid 1px rgba($brand-blue, 0.15);
		text-decoration: none;

		&:hover {
			text-decoration: none;
			border: solid 1px rgba($brand-blue, 0.3);
		}
	}

	&__back-button {
		display: block;
		width: 2rem;
		height: 2rem;
		margin-bottom: 1.25rem;
		padding-top: 0.25rem;
		padding-right: 0.125rem;
		border: solid 1px rgba($brand-blue, 0.5);
		border-radius: 100px;
		text-align: center;

		i {
			color: $brand-blue;
		}

		&:hover {
			border-color: $brand-orange;

			i {
				color: $brand-orange;
			}
		}

	}

	&__related-books {
		.book-items {
			padding-top: 0;
			padding-bottom: 2rem;
			background-color: $body-bg-color;
		}

		.book-item {
			.book-item__text {
				font-size: 1rem;
			}
		}
	}

}

@media(max-width: 576px) {
	.post-single {
		background: rgb(254, 249, 245);
		background: linear-gradient(170deg, rgb(254, 249, 245) 0%, rgb(250, 210, 200) 100%);
	}
}

/* related posts */

.rel-posts {
	position: relative;
	padding: 2.5rem 0;
	box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
	z-index: 2;
	background-color: $sand-bg;

	&:before {

		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		left: 0;
		top: 0;
		pointer-events: none;

		opacity: 0.7;
		background-image: url(../img/noise.png);
		background-repeat: repeat;
	}

	&__title {
		font-size: 1.5rem;
		font-weight: 400;
		color: $brand-blue;
		margin-bottom: 1rem;
	}

}