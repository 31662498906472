.content-collection-list {
	padding: 3rem 0;
	.search-item__lead {
		font-size: 0.9375rem;
	}

	.podcast-item {
		&__inner {
			padding: 1rem;
			background-color: $brand-dark-blue;
		}
	}
}

.collection-card {
	&__icon {
		font-size: 30px;
		line-height: 1.3;
		color: rgb(51, 87, 97);
	}
	&__subtitle {
		margin-bottom: 2rem;
		color: rgb(243, 115, 71);
	}
	&__description {
		margin-bottom: 2rem;
		padding: 1.5rem;
		background-color: rgba(243, 115, 71, 0.08);
		font-size: 0.9375rem;
	}
}



/* ~~~~~~~~ Collection Carousel ~~~~~~~~ */

.collection-carousel {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
	.carousel-inner {
		.carousel-item {
			.card {
				background: transparent;
				border: none;
			}

			&.active {
				display: flex;
			}
		}

		.carousel-item-next,
		.carousel-item-prev {
			display: flex;
		}

	}
}

@media (max-width: 767px) {
	.collection-carousel {
		.carousel-inner {
			.carousel-item {
				> div {
					display: none;
					&:first-child {
						display: block;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {

	.collection-carousel {
		.carousel-inner {
			.carousel-item {
				.card {
					padding: 0.5rem;
				}
			}

			.carousel-item-end.active,
			.carousel-item-next {
				transform: translateX(25%);
			}

			.carousel-item-start.active,
			.carousel-item-prev {
				transform: translateX(-25%);
			}
		}
	}

}

.collection-carousel {
	.carousel-inner {
		.carousel-item-end,
		.carousel-item-start {
			transform: translateX(0);
		}
	}
}