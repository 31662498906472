
.home-section {
	padding-top: 3rem;

	&__search {

		position: relative;
		margin-top: -1rem;
		background-color: transparent;
		z-index: 200;
	}

	&__search-inner {
		padding: 2.5rem 1.5rem;
		background-color: $pure-white;
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.15);
		border-radius: 2px;
		text-align: center;
	}

	&__search-form {
		position: relative;
		input[type=search] {
			padding-left: 3rem;
			border-radius: 100px;
			background-color: $pure-white;
		}

		.btn {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			padding: 0 0.75rem;
			background-color: transparent !important;

			&:hover {
				border-color: transparent;
			}
		}

	}

	.featured-small {
		display: flex;
		height: 100%;
		justify-content: space-between;
	}

	@media (min-width: 768px) {
		.featured-small {
			flex-direction: column !important;
		}

	}

	.featured-medium,
	.featured-large,
	.featured-wide {
		padding-bottom: 0.5rem;
	}

	&__sidebar {
		height: 100%;
		background: rgba(243, 115, 71, 0.08);
		background: linear-gradient(120deg, rgba(243, 115, 71, 0.08) 0%, rgba(243, 115, 71, 0) 100%);
	}

	&__tag-cloud {
		height: 100%;
		min-height: 300px;
		background-color: $brand-orange;
	}

	&__podcast {
		height: 100%;
		background-color: $brand-dark-blue;

		.home-widget {
			padding: 0 !important;
		}
	}

	&__book-new {
		height: 100%;
		background-color: $brand-dark-blue;

		.home-widget {
			padding: 2rem 1.5rem !important;
			color: $pure-white;
			h2, h4 {
				color: $pure-white;
			}
		}
	}

	.home-widget {
		padding: 0.75rem 0.75rem 1rem 0.75rem;

		h3 {
			color: $brand-orange;
			margin-bottom: 0.5rem;
		}

		&__post-list {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding: 0.375rem 0;
			}
		}

		&__list-item {
			font-size: 0.875rem;
			line-height: 1.35;

			height: 3.5625rem;
			overflow: hidden; // trim long titles

			h4 {
				margin-bottom: 0.5rem;
				font-size: 1rem;
			}
		}

		&__cover {
			position: relative;
		}

		&__rank {
			position: absolute;
			top: 0;
			left: 0;
			padding: 0.5rem 0.75rem;
			font-size: 1.125rem;
			font-weight: 500;
			line-height: 1;
			color: #fff;
			z-index: 200;
			background-color: $brand-orange;
		}

		&__book-top-header {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;

			&--lg {
				//
			}

			&--sm {
				height: 100%;
				padding: 2.5rem 1.5rem;
				background-color: $brand-orange;

				h3 {
					font-style: 1.5rem;
					color: $pure-white;
				}
			}
		}

		&__book-top-item {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;

			h4 {
				font-size: 1rem;
				margin: 0.75rem 0;
			}
		}
	}

	&__banner-marketing {
		position: relative;
		height: 100%;
		min-height: 300px;
		padding: 2.5rem;
		text-align: center;
		background-color: $brand-blue;
		color: $pure-white;

		.arrow-down {
			position: absolute;
			left: 1rem;
			right: auto;
			top: 0;
			margin-left: auto;
			margin-right: auto;
			width: 5rem;
			height: auto;

			.brand-arrow__text {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				padding-bottom: 20%;

				text-align: center;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				font-size: 1rem;
				font-weight: 500;
				color: $pure-white;
			}
		}
	}

	&__banner-join {
		position: relative;
		padding: 9rem 0 4rem 0;

		background: $brand-scnd-blue-light;
		color: $brand-blue;

		text-align: center;

		h3 {
			font-size: 1.75rem;
		}

		.arrow-down {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			width: 6.25rem;
			height: auto;

			.brand-arrow__text {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				padding-bottom: 20%;

				text-align: center;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				font-size: 1.25rem;
				font-weight: 500;
				color: $pure-white;
			}
		}
	}

	&__banner-join-content {
		padding: 0 1rem;
		text-align: center;

		h3 {
			color: $brand-dark-blue;
		}
	}

	&__banner-review {

		background: $brand-scnd-purple;
		color: $pure-white;


	}
	&__banner-quote {
		padding: 2.5rem 1rem;

		blockquote {
			position: relative;
			margin: 0;
			padding: 1.25rem 0.5rem 0 2.5rem;
			font-family: $heading-font;
			font-style: italic;
			font-size: 1rem;
			background-color: transparent;
			border: none;
			color: $pure-white;

			cite {
				font-family: Noto Sans, Helvetica, Arial, sans-serif;
				font-size: 1rem;
				font-style: normal;
			}

			&:before {
				position: absolute;
				top: 1rem;
				left: 0rem;
				content: "\201C";
				font-family: Georgia, serif;
				font-size: 3.75rem;
				font-weight: 400;
				line-height: 1;
				color: $pure-white;
			}

		}
	}
}



@media(min-width: 768px) {
	.home-section {

		&__search {
			margin-top: -2rem;
		}
		&__search-inner {
			padding: 3rem 6.25rem;
		}

		&__sidebar {
			.home-widget {
				&__rank {
					padding: 0.25rem 0.5rem;
					font-size: 0.7rem;
					font-weight: 700;
				}
			}
		}

		&__banner-join {
			padding: 4rem 0;

			.arrow-down {
				left: auto;
				right: 1rem;
				margin-left: auto;
				margin-right: 0;
			}
		}

		&__banner-quote {
			padding: 2.5rem 1.5rem;

			blockquote {
				font-size: 1.125rem;
			}
		}

		.home-widget__list-item {
			height: auto;
			overflow: visible;
		}
	}
}

@media(min-width: 992px) {
	.home-section {
		&__banner-quote {
			padding: 2.5rem 2rem;

			blockquote {
				padding: 0.75rem 4rem 0 6rem;
				font-size: 1.25rem;

				&:before {
					font-size: 5.5rem;
					top: 0;
					left: 1.5rem;
				}
			}
		}

		&__banner-marketing {
			min-height: 300px;
			padding: 3rem;
			.arrow-down {
				width: 6.25rem;
				.brand-arrow__text {
					font-size: 1.25rem;
				}
			}
		}

		.home-widget__list-item {
			height: 3.5625rem;
			overflow: hidden; // trim long titles
		}
	}
}

@media(max-width: 992px) {
	.home-section {
		&__sidebar {
			background: none;

			.home-widget {
				padding: 0.75rem 0 1rem 0;

				&__post-list.trim {
					li:last-child {
						display: none;
					}
				}

				&__book-top-list {
					padding-top: 1.5rem;
					padding-bottom: 1.5rem;
					background-color: $brand-scnd-orange-light;
				}
			}
		}
	}
}

